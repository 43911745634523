import React from 'react'
import styled from 'styled-components'
import { graphql, StaticQuery } from 'gatsby'

import { PopularPostsTemplate } from '../../../shared'
import DogIcon from '../img/dog-icon.png'
import { media } from '../../../shared/utils/style-utils'

const Dog = styled.img`
  height: auto;
  position: absolute;
  right: 3%;
  margin: 0;
  ${media.small`
    width: 70px;
    bottom: -1px;
  `}
  ${media.medium`
    bottom: -3px;
    width: 130px;
  `}
`

export default () => (
  <StaticQuery
    query={graphql`
      query PopularPostQuery {
        allMarkdownRemark(
          limit: 1
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: {
            frontmatter: {
              templateKey: { eq: "blog-post" }
              popularPost: { eq: true }
              trendingPost: { eq: false }
              featuredPost: { eq: false }
              homepageHide: { eq: false }
            }
          }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                description
                popularPost
                previewImage {
                  childImageSharp {
                    gatsbyImageData(width: 500, quality: 100)
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <PopularPostsTemplate data={data}>
        <Dog src={DogIcon} alt="Dog icon" />
      </PopularPostsTemplate>
    )}
  />
)
