import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'

import { EmailBannerTemplate } from '../../../shared'
import DogIcon from '../img/email-dog-icon.png'
import { media } from '../../../shared/utils/style-utils'

const Dog = styled.img`
  height: auto;
  position: absolute;
  margin: 0;
  ${media.small`
    width: 70px;
    bottom: -12px;
    right: 2%;
  `}
  ${media.medium`
    bottom: -19px;
    width: 110px;
    right: 3%;
  `}
`

export default () => (
  <StaticQuery
    query={graphql`
      query EmailBannerQuery {
        markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
          frontmatter {
            emailBannerHeading
            emailBannerSubheading
          }
        }
      }
    `}
    render={data => (
      <EmailBannerTemplate data={data}>
        <Dog src={DogIcon} alt="Dog icon" />
      </EmailBannerTemplate>
    )}
  />
)
