import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { media } from '../../../shared/utils/style-utils'
import { selectAmazonLink } from '../../../shared/utils/amazon-links'
import { P, Button } from '../../../shared'

import TrophyIcon from '../img/top-rated-icon.png'
import AwardIcon from '../img/best-value-icon.png'
import BestOfBestIcon from '../img/best-of-best-icon.png'
import BestForBudgetIcon from '../img/best-for-budgets-icon.png'

const Row = styled.div`
  background-color: ${props => props.theme.colors.light};
  border: 1px solid ${props => props.theme.colors.border};
  width: 100%;
  display: grid;
  align-items: center;
  position: relative;
  margin-bottom: 25px;
  ${media.small`
    padding-bottom: 20px;
  `};
  ${media.medium`
    height: 100%;
  `};
  ${media.large`
    grid-template-columns: ${props => props.tableSpacing};
    height: auto;
    min-height: 238px;
    padding: 0;
  `};
`

const BadgeWrapper = styled.div`
  background-color: ${({ badgeColor, theme }) => theme.colors[badgeColor] || `transparent`};
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center;
  padding: 6px;
  font-family: ${props => props.theme.fonts.boldTitle};
  color: ${props => props.theme.colors.light};
  text-transform: uppercase;
`

const Badge = styled.img`
  margin: 0;
  ${media.medium`
    height: 80px;
    width: auto;
  `}
  ${media.large`
    height: 90px;
    width: auto;
  `}
`

const TextBox = styled.div`
  height: 100%;
  font-family: ${props =>
    props.isBold ? props.theme.fonts.boldPrimary : props.theme.fonts.primary};
  display: grid;
  align-content: center;

  &:nth-of-type(1),
  &:nth-of-type(2) {
    padding: 0 11px;
    ${media.small`
      border: none;
      justify-items: center;
    `}
    ${media.medium`
      margin: 0;
      padding: 4px 28px;
    `}
    ${media.large`
      border-left: 1px solid ${props => props.theme.colors.border};
      padding: 22px 28px;
    `}
  }
  ${media.small`
    font-size: 14px;
    line-height: 1.5;
    grid-template-columns: 1fr;
    grid-column-gap: 20px;
    margin: 20px 11px 10px 11px;
    padding: 0 6px;
    border-top: 1px solid ${props => props.theme.colors.border};
    justify-content: center;
  `}
  ${media.medium`    
    font-size: 14px;
    line-height: 1.5;
    justify-content: start;
    margin: 20px 28px 0 28px;
    padding: 0;
    line-height: 30px;
  `}
  ${media.large`
    margin: 0;
    padding: 22px 24px;
    line-height: 24px;
    border-top: none;
    border-left: 1px solid ${props => props.theme.colors.border};
  `};
`

const ProductBox = styled(TextBox)`
  flex-direction: column;
  ${media.large`
    height: calc(100% + 78px);
    padding: 22px 0;
  `}
`

const StyledButton = styled(Button)`
  width: fit-content;

  background-color: ${props => props.theme.colors.ctaBlue};
  border: none;
  border-radius: 12px;

  font-family: ${props => props.theme.fonts.primary};
  color: white;
  text-transform: capitalize;

  font-size: 20px;
  padding: 10px 20px;

  ${media.small`
    margin-top: 0;
    font-size: 20px;
    padding: 16px 28px;
  `};
  ${media.medium`
    font-size: 20px;
    padding: 10px 20px;
  `};
  ${media.large`
    margin-top: 17px;
    font-size: 20px;
    padding: 10px 20px;
  `};

  &:hover {
    background-color: ${props => props.theme.colors.ctaBlueHover};
    border: none;
  }
`

const ProductImgWrapper = styled.div`
  width: auto;
  ${media.small`
    height: 194px;
    margin: 0 auto 20px auto;
  `};
  ${media.medium`
    height: 150px;
    margin: 0 auto 18px auto;
  `};
`

const ProductName = styled.p`
  text-align: center;
  font-family: ${props => props.theme.fonts.primary};
  letter-spacing: 0.02em;
  line-height: 22px;
  margin: 0;
  ${media.small`
    font-size: 20px;
    margin-bottom: 20px;
  `};
  ${media.large`
    margin: 0;
    font-size: 16px;
  `};
`

const StyledLink = styled(OutboundLink)`
  text-decoration: none;
  padding: 0;
  margin: 0;
  color: ${props => props.theme.colors.dark};
`

const MobileLabel = styled(P)`
  font-family: ${props => props.theme.fonts.boldTitle};
  text-transform: uppercase;
  ${media.small`
    align-self: center;
    font-size: 16px !important;
    margin: 3px 0 12px 0;
  `};
  ${media.medium`
    font-size: 20px !important;
    align-self: start;
  `};
  ${media.large`
    padding: 6px 0;
    display: none;
    font-size: 16px !important;
  `};
`

const BADGES_MAP = {
  topRated: { img: TrophyIcon, bgColor: 'topRated', label: 'Top Rated' },
  bestValue: { img: AwardIcon, bgColor: 'bestValue', label: 'Best Value' },
  bestOfBest: { img: BestOfBestIcon, bgColor: 'bestOfBest', label: 'Best of Best' },
  bestForBudgets: { img: BestForBudgetIcon, bgColor: 'bestForBudgets', label: 'Best for Budgets' },
}

const TableRow = ({ product, tableSpacing }) => {
  const {
    productName,
    image,
    shopUrl,
    tldr,
    pros,
    cons,
    isTopRated: topRated,
    isBestValue: bestValue,
    bestOfBest,
    bestForBudgets,
  } = product

  const [selectedUrl, setSelectedUrl] = useState(shopUrl[0])
  const [tldrVisible, setTldrVisible] = useState(false)
  const [prosVisible, setProsVisible] = useState(false)
  const [consVisible, setConsVisible] = useState(false)

  useEffect(() => {
    const url = selectAmazonLink(shopUrl)
    setSelectedUrl(url)
  }, [shopUrl])

  const productImage = getImage(image)

  let isMobile = false
  if (typeof window !== `undefined`) {
    const tabletMQ = window.matchMedia('(max-width: 768px)')
    isMobile = tabletMQ.matches
  }

  const TextRow = ({ visible, setVisible, label, copy }) => (
    <TextBox
      isBold={label === 'Key Takeaway'}
      visible={visible}
      onClick={e => {
        if (isMobile) {
          e.preventDefault()
          setVisible(true)
        }
      }}
    >
      <MobileLabel>{label}</MobileLabel>
      <span>{copy}</span>
    </TextBox>
  )

  let badgeType = null
  if (topRated) badgeType = 'topRated'
  if (bestValue) badgeType = 'bestValue'
  if (bestOfBest) badgeType = 'bestOfBest'
  if (bestForBudgets) badgeType = 'bestForBudgets'

  const badge = BADGES_MAP[badgeType]

  return (
    <StyledLink href={selectedUrl} target="_blank" rel="noopener noreferrer">
      <Row tableSpacing={tableSpacing}>
        <BadgeWrapper badgeColor={badge?.bgColor}>
          {badge && (isMobile ? badge.label : <Badge src={badge.img} alt={badge.label} />)}
        </BadgeWrapper>
        <ProductBox style={{ border: 'none' }}>
          {image && (
            <ProductImgWrapper>
              <GatsbyImage
                image={productImage}
                alt={productName}
                style={{ height: '100%', width: '100%' }}
                imgStyle={{ objectFit: 'contain' }}
              />
            </ProductImgWrapper>
          )}
          <ProductName>{productName}</ProductName>
          <StyledButton>Check Price</StyledButton>
        </ProductBox>
        <TextRow
          label="Key Takeaway"
          copy={tldr}
          visible={tldrVisible || !isMobile}
          setVisible={setTldrVisible}
        />
        <TextRow
          label="PROS"
          copy={pros}
          visible={prosVisible || !isMobile}
          setVisible={setProsVisible}
        />
        <TextRow
          label="CONS"
          copy={cons}
          visible={consVisible || !isMobile}
          setVisible={setConsVisible}
        />
      </Row>
    </StyledLink>
  )
}

export default TableRow
